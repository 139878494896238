import "./faqs.js";
import "./theme.js";
import "./solutions.js";

const hamburger = document.querySelector("#hamburger");
const navbar = document.querySelector("#navbar");

hamburger.addEventListener("click", () => {
    navbar.classList.toggle("navbar-active");
});
